<style>
th {
  position: sticky;
  top: 0;
  background: white;
}
</style>
<template>
  <div class="vx-row my-4">
    <div class="vx-col">
      <div class="vx-row mb-6">
        <div class="vx-col flex items-center justify-items-start gap-2">
          <vs-button
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-download"
            @click="onClickExportToExcel"
          >
            Export to Excel</vs-button
          >
          <vs-button
            size="small"
            color="danger"
            icon-pack="feather"
            icon="icon-printer"
            @click="onClickExportToPDF"
          >
            Export to PDF</vs-button
          >
          <vs-button
            size="small"
            color="success"
            icon-pack="feather"
            icon="icon-check"
            @click="onClickGenerateRHP"
          >
            Generate RHP</vs-button
          >
        </div>
      </div>
    </div>
    <div class="core vx-col md:w-1/1 w-full mb-base">
      <vs-table
        search
        stripe
        border
        multiple
        description
        maxHeight="500px"
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="onSearchTable"
        v-model="selected_rows"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th>Action</vs-th>
          <vs-th>No</vs-th>
          <vs-th>Collection Plan ID</vs-th>
          <vs-th>Collection Type</vs-th>
          <vs-th>Invoice Codes</vs-th>
          <vs-th>Sales / Driver</vs-th>
          <vs-th># of Invoice</vs-th>
          <vs-th>Remaining Value</vs-th>
          <vs-th># of Collection</vs-th>
          <vs-th>Payment Value</vs-th>
          <!-- <vs-th>Delta</vs-th> -->
          <vs-th># of no Payment</vs-th>
          <vs-th>no Payment Value</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="whitespace-no-wrap">
              <div class="vx-row gap-2">
                <vs-button
                  @click.stop="handleShow(data[indextr].id)"
                  size="small"
                  color="success"
                  icon-pack="feather"
                  icon="icon-eye"
                  title="Edit"
                  style="margin-right: 5px"
                />
                <vs-button
                  @click="onClickPrint(data[indextr].id)"
                  size="small"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-printer"
                />
              </div>
            </vs-td>
            <vs-td>{{ table.rowNumberStart + indextr }} </vs-td>
            <vs-td>{{ tr.uuid }}</vs-td>
            <vs-td>{{ tr.type }}</vs-td>
            <vs-td>{{ tr.billing_order_codes }}</vs-td>
            <vs-td>{{ tr.sales_name }}</vs-td>
            <vs-td>{{ tr.count_invoice }}</vs-td>
            <vs-td>{{ tr.total_remaining_value | formatNumber }}</vs-td>
            <vs-td>{{ tr.of_collection }}</vs-td>
            <vs-td>{{ tr.collection_value | formatNumber }}</vs-td>
            <!-- <vs-td>{{ tr.delta | formatNumber }}</vs-td> -->
            <vs-td>{{ tr.of_no_payment }}</vs-td>
            <vs-td>{{ tr.no_payment_value | formatNumber }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatNumber } from "../../../../utils/common";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default {
  props: {
    date: {
      type: String,
      default: "",
    },
    territoryID: {
      type: Number,
    },
  },
  data() {
    return {
      baseUrl: "api/sfa/v1/collection-plan/list-collection-plan",
      selected_rows: [],
      table: {
        data: [],
        is_approved_list: true,
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        status: 1,
        end: 0,
        rowNumberStart: 1,
      },
    };
  },
  methods: {
    onClickGenerateRHP() {
      let ids = this.selected_rows.map((item) => item.id);
      if (ids.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select at least one collection plan",
          position: "right-top",
        });
        return;
      }

      // call api to generate RHP
      this.$vs.loading();
      this.$http
        .post(`api/sfa/v1/collection-plan/generated-rhp`, {
          ids: ids,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "RHP has been generated",
              position: "right-top",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "right-top",
            });
          }

          this.$vs.loading.close();
        });
    },
    onClickPrint(id) {
      this.$emit("data-print", id, "excel");
    },
    onSearchTable(search) {
      this.table.search = search;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        is_approved_list: true,
        status: 1,
        territory_id: this.territoryID,
      });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        is_approved_list: true,
        status: 1,
        territory_id: this.territoryID,
      });
    },
    handleShow(id) {
      this.$router.push({
        name: "collection-plan-manage-showv2",
        params: { id: id },
        query: { hide_approved: true },
      });
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        is_approved_list: true,
        status: 1,
        territory_id: this.territoryID,
      });
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        is_approved_list: true,
        status: 1,
        territory_id: this.territoryID,
      });
    },
    getData(params) {
      if (this.date == null) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.rowNumberStart = resp.data.row_number_start;
            if (resp.data.records == null) {
              this.table.data = [];
            } else {
              this.table.data = resp.data.records;
            }

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    onClickExportToPDF() {
      let ids = this.selected_rows.map((item) => item.id);
      if (ids.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select at least one collection plan",
          position: "right-top",
        });
        return;
      }

      // open new tab with route
      const routeData = this.$router.resolve({
        name: "collection-plan-manage-print-pdf",
        query: { collection_plan_ids: ids },
      });
      window.open(routeData.href, "_blank");
    },
    onClickExportToExcel() {
      if (this.selected_rows.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select at least one collection plan",
          position: "right-top",
        });
        return;
      }

      this.$vs.loading();
      let params = {
        status: 1,
        is_export_excel: true,
        is_approved_list: true,
        collection_plan_ids: this.selected_rows.map((item) => item.id),
        date: this.date,
        territory_id: this.territoryID,
      };

      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_MULTIPLE_${currentDate}`;
      moment(this.startDate).format("YYYY-MM-DD") +
        this.$http
          .get(`api/sfa/v1/collection-plan/multiple-export`, {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
            params: params,
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([resp]));
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;

              fileLink.setAttribute("download", fileTitle + ".xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          });
    },
    // doMultipleExportPdf(type, tableIds) {
    //   this.$vs.loading();
    //   const currentDate = `${moment().format("YYYY-MM-DD")}`;
    //   var fileTitle = `COLLECTION_PLAN_REPORT_MULTIPLE_${currentDate}`;
    //   // tableIds: [1,2,3] to ids[]=1&ids[]=2&ids[]=3
    //   let params = {
    //     status: 1,
    //     is_export_excel: true,
    //     is_approved_list: true,
    //     collection_plan_ids: this.selected_rows.map((item) => item.id),
    //     date: this.date,
    //     territory_id: this.territoryID,
    //   };
    //   this.$http
    //     .get(`api/sfa/v1/collection-plan/multiple-export`, {
    //       params: params,
    //     })
    //     .then((resp) => {
    //       this.$vs.loading.close();

    //       if (resp.status == "error") {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         let data = resp.data;
    //         const doc = new jsPDF("l", "mm", [297, 210]);
    //         console.log("YYYY", resp);
    //         const collectionPlans = data; // Array of collection plan data
    //         collectionPlans.forEach((collectionPlan, index) => {
    //           if (index > 0) {
    //             doc.addPage();
    //           }
    //           let collectionPlanLines = collectionPlan.dafins;

    //           // const createdAt = this.dateFormat(collectionPlan.date);
    //           let date = moment().format("DD-MM-YYYY");

    //           let fontSize = 9;

    //           doc.setFont("helvetica").setFontSize(fontSize);

    //           doc.text("Daftar Tagihan Kolektor/Sales", 145, 10, {
    //             align: "center",
    //           });
    //           doc.text("Code:" + collectionPlan.no_dafin, 280, 25, {
    //             align: "right",
    //           });
    //           doc.text("Branch:" + collectionPlan.branch, 238, 30, {
    //             align: "right",
    //           });
    //           doc.text(
    //             "Kolektor / Salesman:" + collectionPlan.collector,
    //             261,
    //             35,
    //             {
    //               align: "right",
    //             }
    //           );
    //           doc.text(
    //             "Tanggal RHP:" +
    //               moment(String(collectionPlan.date)).format("DD MMM YYYY"),
    //             245,
    //             40,
    //             {
    //               align: "right",
    //             }
    //           );

    //           fontSize = 9;
    //           doc.setFont("helvetica").setFontSize(fontSize);

    //           const head = [
    //             [
    //               "no",
    //               "Cust Kode",
    //               "Cust Name",
    //               "Tgl JT Faktur",
    //               "No. Faktur / Tukar Faktur",
    //               "Nilai Faktur / (Sisa Faktur)",
    //               "Uang Tunai",
    //               "Nominal Giro",
    //               "Nama Bank",
    //               "No. BG",
    //               "Tgl JT Tempo",
    //               "Potongan Promo / Retur Dll",
    //               "Keterangan",
    //             ],
    //           ];

    //           let line = [];

    //           // console.log("v1", writeOffLines[0])
    //           // console.log("v2", writeOffLines[1])

    //           let total = 0;
    //           let no = 1;
    //           collectionPlanLines.map((v, i) => {
    //             console.log("LLL", v);
    //             line.push([
    //               no++,
    //               v.customer_code,
    //               v.customer_name,
    //               moment(String(v.due_date)).format("DD.MM.YYYY"),
    //               v.no_document,
    //               v.bill_total
    //                 .toFixed(2)
    //                 .toString()
    //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    //               v.cash_value
    //                 .toFixed(2)
    //                 .toString()
    //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    //               v.giro_value
    //                 .toFixed(2)
    //                 .toString()
    //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    //               v.bank_name,
    //               v.no_bg,
    //               moment(String(v.due_date)).format("DD.MM.YYYY"),
    //               v.discount,
    //               v.reason,
    //             ]);

    //             total += v.bill_total;
    //             console.log("total disini", total);

    //             this.total = total;
    //           });

    //           line.push([
    //             { content: "Total ", colSpan: 5 },
    //             {
    //               content: collectionPlan.bill_total_value
    //                 .toFixed(2)
    //                 .toString()
    //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    //               halign: "right",
    //             },
    //           ]);

    //           autoTable(doc, {
    //             startY: 45,
    //             head: head,
    //             body: line,
    //             margin: { right: 10, left: 10 },
    //             theme: "grid",
    //             columnStyles: {
    //               0: {
    //                 fontSize: 8,
    //                 cellWidth: 8,
    //               },
    //               1: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 24,
    //               },
    //               2: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 25,
    //               },
    //               3: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 15,
    //               },
    //               4: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 20,
    //               },
    //               5: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 25,
    //               },
    //               6: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 24,
    //               },
    //               7: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 24,
    //               },
    //               8: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 22,
    //               },
    //               9: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 20,
    //               },
    //               10: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 15,
    //               },
    //               11: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 25,
    //               },
    //               12: {
    //                 halign: "middle",
    //                 fontSize: 8,
    //                 cellWidth: 30,
    //               },
    //             },
    //           });

    //           //   autoTable(doc, {
    //           //     startY: 180,
    //           //     head: [],
    //           //     body: [["Terbilang ", ": " + angkaTerbilang(total.toFixed(2)).toUpperCase() + " RUPIAH"]],
    //           //   });

    //           doc.text("Diserahkan (Faktur)", 20, 150); //(text,x,y)
    //           doc.text("(Admin AR)", 25, 175); //(text,x,y)

    //           doc.text("Diperiksa", 65, 150); //(text,x,y)
    //           doc.text("(BAS)", 68, 175); //(text,x,y)

    //           doc.text("Diterima (Faktur)", 90, 150); //(text,x,y)
    //           doc.text("(ASM/ASS)", 95, 175); //(text,x,y)

    //           doc.text("Diserahkan", 127, 150); //(text,x,y)
    //           doc.text("(Kolektor / Sales)", 125, 175); //(text,x,y)

    //           doc.text("Diterima (Hasil Tagihan)", 155, 150); //(text,x,y)
    //           doc.text("(Kasir)", 165, 175); //(text,x,y)

    //           doc.text("Diterima (Faktur Kembali)", 195, 150); //(text,x,y)
    //           doc.text("(Admin AR)", 205, 175); //(text,x,y)

    //           doc.text("Diketahui", 245, 150); //(text,x,y)
    //           doc.text("(BAS)", 247, 175); //(text,x,y)
    //         });
    //         doc.save("collection-plan" + fileTitle + ".pdf");
    //         this.$vs.loading.close();
    //       }
    //     });
    // },
  },
  filters: {
    formatNumber: function (value) {
      if (value) {
        return formatNumber(value);
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
